import * as React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

import * as styles from "./styles.module.css";

import logo from "../images/cc-intel-jp.jpg";

// markup
const GdprPage = () => {
  return (
    <main>
      <Helmet>
        <title>Cleancore Intelligence - Privacy Policy</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;600&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <div className={styles.maxWrapper}>
        <div className={styles.banner}>
          <img className={styles.mainImg} src={logo} alt="" />
          <p className={styles.subheading}>
            Digitally track and optimise cleaning activity
          </p>
        </div>
        <h1 className={styles.headingVisible}>
          Cleancore Intelligence Ltd customer privacy notice
        </h1>

        <p>
          This privacy notice tells you what to expect us to do with your
          personal information.
        </p>

        <ul>
          <li>Contact details</li>
          <li>What information we collect, use, and why</li>
          <li>Lawful bases and data protection rights</li>
          <li>Where we get personal information from</li>
          <li>How long we keep information</li>
          <li>Who we share information with</li>
          <li>How to complain</li>
        </ul>

        <h2>Contact details</h2>
        <p>
          Email:{" "}
          <a href="mailto:info@cleancoreintelligence.com">
            info@cleancoreintelligence.com
          </a>
        </p>
        <h2>What information we collect, use, and why</h2>
        <p>
          We collect or use the following information to{" "}
          <b>provide services and goods, including delivery:</b>
        </p>

        <ul>
          <li>Email address</li>
        </ul>

        <h2>Lawful bases and data protection rights</h2>

        <div className={styles.infoBox}>
          <p>
            {" "}
            Under UK data protection law, we must have a “lawful basis” for
            collecting and using your personal information. There is a list of
            possible lawful bases in the UK GDPR. You can find out more about
            lawful bases on the ICO's website.
          </p>
          <p>
            Which lawful basis we rely on may affect your data protection rights
            which are in brief set out below. You can find out more about your
            data protection rights and the exemptions which may apply on the
            ICO's website:
          </p>
          <ul>
            <li>
              Your right of access - You have the right to ask us for copies of
              your personal information. You can request other information such
              as details about where we get personal information from and who we
              share personal information with. There are some exemptions which
              means you may not receive all the information you ask for.{" "}
              <a href="https://ico.org.uk/for-organisations/advice-for-small-organisations/create-your-own-privacy-notice/your-data-protection-rights/#roa">
                You can read more about this right here.
              </a>
            </li>
            <li>
              Your right to rectification - You have the right to ask us to
              correct or delete personal information you think is inaccurate or
              incomplete.{" "}
              <a href="https://ico.org.uk/for-organisations/advice-for-small-organisations/create-your-own-privacy-notice/your-data-protection-rights/#rtr">
                You can read more about this right here.
              </a>
            </li>
            <li>
              Your right to erasure - You have the right to ask us to delete
              your personal information.{" "}
              <a href="https://ico.org.uk/for-organisations/advice-for-small-organisations/create-your-own-privacy-notice/your-data-protection-rights/#rte">
                You can read more about this right here.
              </a>
            </li>
            <li>
              Your right to restriction of processing - You have the right to
              ask us to limit how we can use your personal information.{" "}
              <a href="https://ico.org.uk/for-organisations/advice-for-small-organisations/create-your-own-privacy-notice/your-data-protection-rights/#rtrop">
                You can read more about this right here.
              </a>
            </li>
            <li>
              Your right to object to processing - You have the right to object
              to the processing of your personal data.{" "}
              <a href="https://ico.org.uk/for-organisations/advice-for-small-organisations/create-your-own-privacy-notice/your-data-protection-rights/#rto">
                You can read more about this right here.
              </a>
            </li>
            <li>
              Your right to data portability - You have the right to ask that we
              transfer the personal information you gave us to another
              organisation, or to you.{" "}
              <a href="https://ico.org.uk/for-organisations/advice-for-small-organisations/create-your-own-privacy-notice/your-data-protection-rights/#rtdp">
                You can read more about this right here.
              </a>
            </li>
            <li>
              Your right to withdraw consent - When we use consent as our lawful
              basis you have the right to withdraw your consent at any time.{" "}
              <a href="https://ico.org.uk/for-organisations/advice-for-small-organisations/create-your-own-privacy-notice/your-data-protection-rights/#rtwc">
                You can read more about this right here.
              </a>
            </li>
          </ul>
          <p>
            If you make a request, we must respond to you without undue delay
            and in any event within one month.
          </p>
          <p>
            To make a data protection rights request, please contact us using
            the contact details at the top of this privacy notice.
          </p>
        </div>
        <h2>Our lawful bases for the collection and use of your data</h2>
        <p>
          Our lawful bases for collecting or using personal information{" "}
          <b>to provide services and goods are:</b>
        </p>
        <ul>
          <li>
            Contract - we have to collect or use the information so we can enter
            into or carry out a contract with you. All of your data protection
            rights may apply except the right to object.
          </li>
        </ul>

        <h2>Where we get personal information from</h2>
        <ul>
          <li>
            All personal information is directly from you when you register on
            our system.
          </li>
        </ul>
        <h2>How long we keep information</h2>
        <ul>
          <li>
            Our data retention policy is designed to only store personal data as
            long as you authorise us to do so. This means while your contract
            with us is active, which typically renews on a 12-month basis.
            Cleancore will permanently delete personal data on our systems and
            those of our data processors at the end of the contract period, or
            at the date of an early termination of the contract at any time
            should you wish to do so.
          </li>
          <li>
            Anonymous raw data collected by our system (data collected from our
            IoT sensors) is stored for a period of 1 year after the contract
            ends, at which point it is also permanently deleted, unless a
            request is made for it to be deleted earlier. A request to delete
            this data can be made from the contract's main point of contact via
            email to info@cleancoreintelligence.com.
          </li>
        </ul>
        <h2>Who we share information with</h2>
        <h3>Auth0 (Okta)</h3>
        <p>
          This data processor does the following activities for us: We use Auth0
          as a 3rd party authentication service. Auth0 store the email address
          and password of registered users of Cleancore systems. More details on
          Auth0's role as a data controller can be found on their GDPR page at
          https://auth0.com/docs/secure/data-privacy-and-compliance/gdpr
        </p>

        <h2>How to complain</h2>
        <p>
          If you have any concerns about our use of your personal data, you can
          make a complaint to us using the contact details at the top of this
          privacy notice. If you remain unhappy with how we've used your data
          after raising a complaint with us, you can also complain to the ICO.
        </p>
        <p>The ICO's address:</p>
        <p>Information Commissioner's Office</p>
        <p>Wycliffe House</p>
        <p>Water Lane</p>
        <p>Wilmslow</p>
        <p>Cheshire</p>
        <p>SK9 5AF</p>
        <p>Helpline number: 0303 123 1113</p>
        <p>Website: https://www.ico.org.uk/make-a-complaint</p>
        <h2>Last updated</h2>
        <p>25th November 2024</p>

        <footer>
          <Link className={styles.footerLinks} to="/">
            Home
          </Link>
          <Link className={styles.footerLinks} to="/gdpr">
            Privacy policy
          </Link>
        </footer>
      </div>
    </main>
  );
};

export default GdprPage;
